import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchJSON } from 'api/fetch';
import { RootState } from 'initializers/types';
import { createSelector } from 'reselect';

import { MANAGER_LOG_SLICE } from './constants';

export interface SearchNote {
  id: number;
  text: string;
  log_date: string;
  created_at: string;
  board_name: string;
  manager_name: string;
  avatar_url: string;
  editable: boolean;
}

interface SearchState {
  results: SearchNote[];
  loading: boolean;
  error: string | null;
  query: string;
}

export interface Note {
  id: number;
  text: string;
  log_date: string;
  created_at: string;
  avatar_url: string;
  manager_name: string;
}

export interface Board {
  id: number;
  name: string;
  description: string | null;
  notes: Note[];
  showBanner?: boolean;
  archived_at: string;
}

export interface UpdateBoardData {
  id: number;
  name: string;
  description: string | null;
}

export interface LaborAndSalesData {
  event_name?: string;
  degrees_max?: number;
  degrees_min?: number;
  weather_icon?: string;
  has_timecards?: boolean;
  labor_today?: string;
  labor_yesterday?: string;
  labor_percentage_today?: number;
  labor_percentage_yesterday?: number;
  sales_today?: string;
  sales_yesterday?: string;
}

export interface LaborStats {
  num_shifts?: number;
  total_hours?: number;
  missed_breaks?: number;
  labor_today?: string;
  labor_last_week?: string;
  sales_today?: string;
  sales_last_week?: string;
  labor_percentage_today?: number;
  labor_percentage_last_week?: number;
  overtime_costs?: string;
  day_of_week?: string;
}

export interface Shift {
  user_name: string;
  avatar_url: string;
  duration: number;
}

export interface TopShiftsData {
  top_shifts: Shift[];
  manager_shifts: Shift[];
}

export interface HourlyLaborAndSalesData {
  hourly_sales: number[];
  hourly_labor: number[];
}

export interface ShiftFeedbackData {
  average: number;
}

export interface ManagerLogState {
  date: string;
  boards: Board[];
  boardsLoading: boolean;
  error: string | null;
  showBanner: boolean;
  laborAndSales: {
    data: LaborAndSalesData | null;
    loading: boolean;
    error: string | null;
  };
  laborStats: {
    data: LaborStats | null;
    loading: boolean;
    error: string | null;
  };
  topShifts: {
    data: TopShiftsData | null;
    loading: boolean;
    error: string | null;
  };
  search: SearchState;
  hourlyLaborAndSales: {
    data: HourlyLaborAndSalesData | null;
    loading: boolean;
    error: string | null;
  };
  shiftFeedback: {
    data: ShiftFeedbackData | null;
    loading: boolean;
    error: string | null;
  };
}

const initialState: ManagerLogState = {
  date: '',
  boards: [],
  boardsLoading: true,
  error: null,
  showBanner: false,
  laborAndSales: {
    data: null,
    loading: false,
    error: null,
  },
  laborStats: {
    data: null,
    loading: false,
    error: null,
  },
  topShifts: {
    data: null,
    loading: false,
    error: null,
  },
  search: {
    results: [],
    loading: false,
    error: null,
    query: '',
  },
  hourlyLaborAndSales: {
    data: null,
    loading: false,
    error: null,
  },
  shiftFeedback: {
    data: null,
    loading: false,
    error: null,
  },
};

// Thunks
export const fetchBoards = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/fetchBoards`,
  (date: string) =>
    fetchJSON(`/manager_log?date=${date}`, {
      // Headers needed to return JSON
      headers: {
        Accept: 'application/json',
      },
    })
);

export const fetchLaborAndSales = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/fetchLaborAndSales`,
  (date: string) =>
    fetchJSON(`/manager_log/widgets/labor_and_sales?date=${date}`, {
      headers: {
        Accept: 'application/json',
      },
    })
);

export const fetchLaborStats = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/fetchLaborStats`,
  (date: string) =>
    fetchJSON(`/manager_log/widgets/labor_stats?date=${date}`, {
      headers: {
        Accept: 'application/json',
      },
    })
);

export const fetchTopShifts = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/fetchTopShifts`,
  (date: string) =>
    fetchJSON(`/manager_log/widgets/top_shifts?date=${date}`, {
      headers: {
        Accept: 'application/json',
      },
    })
);

export const fetchHourlyLaborAndSales = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/fetchHourlyLaborAndSales`,
  (date: string) =>
    fetchJSON(`/manager_log/widgets/hourly_labor_and_sales?date=${date}`, {
      headers: {
        Accept: 'application/json',
      },
    })
);

export const searchNotes = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/searchNotes`,
  async (query: string) => {
    const response = await fetchJSON(
      `/manager_log/notes?query=${encodeURIComponent(query)}`,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    );
    return response;
  }
);

export const fetchShiftFeedback = createAsyncThunk(
  `${MANAGER_LOG_SLICE}/fetchShiftFeedback`,
  (date: string) =>
    fetchJSON(
      `/manager_log/widgets/shift_feedback.json?date=${date}&end_date=${date}`,
      {
        headers: {
          Accept: 'application/json',
        },
      }
    )
);

// Slice
const managerLogSlice = createSlice({
  name: MANAGER_LOG_SLICE,
  initialState,
  reducers: {
    setDate(state, action) {
      state.date = action.payload;
    },
    updateBoard(state, action: PayloadAction<UpdateBoardData>) {
      state.boards = state.boards.map(board => {
        if (board.id === action.payload.id) {
          return {
            ...board,
            name: action.payload.name,
            description: action.payload.description,
          };
        }
        return board;
      });
    },
    clearSearch(state) {
      state.search = {
        ...initialState.search,
        loading: false,
      };
    },
    setSearchQuery(state, action) {
      state.search.query = action.payload;
      if (action.payload) {
        state.search.loading = true;
      }
    },
    deleteBoard(state, action: PayloadAction<number>) {
      state.boards = state.boards.filter(
        (board: Board) => board.id !== action.payload
      );
    },
  },
  extraReducers: builder => {
    builder
      .addCase(fetchBoards.pending, state => {
        state.boardsLoading = true;
        state.error = null;
      })
      .addCase(fetchBoards.fulfilled, (state, action) => {
        state.boardsLoading = false;
        state.boards = action.payload;
      })
      .addCase(fetchBoards.rejected, (state, action) => {
        state.boardsLoading = false;
        state.error = action.error.message || 'Failed to fetch boards';
      })
      .addCase(fetchLaborAndSales.pending, state => {
        state.laborAndSales.loading = true;
        state.laborAndSales.error = null;
      })
      .addCase(fetchLaborAndSales.fulfilled, (state, action) => {
        state.laborAndSales.loading = false;
        state.laborAndSales.data = action.payload;
      })
      .addCase(fetchLaborAndSales.rejected, (state, action) => {
        state.laborAndSales.loading = false;
        state.laborAndSales.error =
          action.error.message || 'Failed to fetch labor and sales data';
      })
      .addCase(fetchLaborStats.pending, state => {
        state.laborStats.loading = true;
        state.laborStats.error = null;
      })
      .addCase(fetchLaborStats.fulfilled, (state, action) => {
        state.laborStats.loading = false;
        state.laborStats.data = action.payload;
      })
      .addCase(fetchLaborStats.rejected, (state, action) => {
        state.laborStats.loading = false;
        state.laborStats.error =
          action.error.message || 'Failed to fetch labor stats data';
      })
      .addCase(fetchTopShifts.pending, state => {
        state.topShifts.loading = true;
        state.topShifts.error = null;
      })
      .addCase(fetchTopShifts.fulfilled, (state, action) => {
        state.topShifts.loading = false;
        state.topShifts.data = action.payload;
      })
      .addCase(fetchTopShifts.rejected, (state, action) => {
        state.topShifts.loading = false;
        state.topShifts.error =
          action.error.message || 'Failed to fetch top shifts data';
      })
      .addCase(fetchHourlyLaborAndSales.pending, state => {
        state.hourlyLaborAndSales.loading = true;
        state.hourlyLaborAndSales.error = null;
      })
      .addCase(fetchHourlyLaborAndSales.fulfilled, (state, action) => {
        state.hourlyLaborAndSales.loading = false;
        state.hourlyLaborAndSales.data = action.payload;
      })
      .addCase(fetchHourlyLaborAndSales.rejected, (state, action) => {
        state.hourlyLaborAndSales.loading = false;
        state.hourlyLaborAndSales.error =
          action.error.message || 'Failed to fetch hourly labor and sales data';
      })
      .addCase(searchNotes.pending, state => {
        state.search.loading = true;
        state.search.error = null;
      })
      .addCase(searchNotes.fulfilled, (state, action) => {
        state.search.loading = false;
        state.search.results = action.payload;
      })
      .addCase(searchNotes.rejected, (state, action) => {
        state.search.loading = false;
        state.search.error = action.error.message || 'Failed to search notes';
      })
      .addCase(fetchShiftFeedback.pending, state => {
        state.shiftFeedback.loading = true;
        state.shiftFeedback.error = null;
      })
      .addCase(fetchShiftFeedback.fulfilled, (state, action) => {
        state.shiftFeedback.loading = false;
        state.shiftFeedback.data = action.payload;
      })
      .addCase(fetchShiftFeedback.rejected, (state, action) => {
        state.shiftFeedback.loading = false;
        state.shiftFeedback.error =
          action.error.message || 'Failed to fetch shift feedback data';
      });
  },
});

// Actions
export const {
  setDate,
  updateBoard,
  clearSearch,
  setSearchQuery,
  deleteBoard,
} = managerLogSlice.actions;

// Selectors
export const selectDate = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).date;

export const selectBoards = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).boards;

export const selectBoardsLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).boardsLoading;

export const selectShowBanner = createSelector([selectBoards], boards => {
  const managerNotesBoard = boards?.find(
    (board: Board) => board.name === 'Manager Notes'
  );
  return !!managerNotesBoard?.showBanner;
});

export const selectLaborAndSales = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborAndSales.data;

export const selectLaborAndSalesLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborAndSales.loading;

export const selectLaborAndSalesError = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborAndSales.error;

export const selectLaborStats = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborStats.data;

export const selectLaborStatsLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborStats.loading;

export const selectLaborStatsError = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).laborStats.error;

export const selectTopShifts = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).topShifts.data;

export const selectTopShiftsLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).topShifts.loading;

export const selectTopShiftsError = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).topShifts.error;

export const selectSearchResults = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).search.results;

export const selectSearchLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).search.loading;

export const selectSearchError = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).search.error;

export const selectSearchQuery = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).search.query;

export const selectHourlyLaborAndSales = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).hourlyLaborAndSales.data;

export const selectHourlyLaborAndSalesLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).hourlyLaborAndSales.loading;

export const selectHourlyLaborAndSalesError = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).hourlyLaborAndSales.error;

export const selectShiftFeedback = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).shiftFeedback.data;

export const selectShiftFeedbackLoading = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).shiftFeedback.loading;

export const selectShiftFeedbackError = (state: RootState) =>
  state.get(MANAGER_LOG_SLICE).shiftFeedback.error;

export const { reducer } = managerLogSlice;
